import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/shared';
import { select, Store } from '@ngrx/store';
import { ClientDataActions, ClientModels, ClientSelectors } from '../../../client';
import { MemberDataActions, MemberModels, MemberSelectors } from '../../../member';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppDataActions, AppSelectors } from '../..';
import { CalculatorActions } from 'src/app/calculator/state';
import { HttpClient } from '@angular/common/http';
import { PagesDataActions, PagesSelectors } from 'src/app/pages';
import { MetadataActions, MetadataSelectors } from 'src/app/metadata';
import { Metadata } from 'src/app/client/models';
import { PageDefinition } from 'src/app/pages/models';
import { Auth0Service } from 'src/app/shared/services/auth/auth0.service';
import { AuthenticationRequest } from 'src/app/member/models/authentication-request';
import { MemberService } from 'src/app/member/services/member-service';
import { VerifyBadgeRequest } from 'src/app/member/models/verify-badge-request';
import { Member, MemberInfo } from 'src/app/member/models/member';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {
  
  logoUrl:string = '';
  userName:string = 'Member';
  statusText:string = '';
  isDataFetched:Boolean = false;
  progressBarValue = 0;

  subs:Subscription = new Subscription();
  beneficiary:MemberModels.MemberBeneficiary;
  member:MemberModels.Member;
  client:ClientModels.Client;
  pageDefs:PageDefinition[];
  firstPageId:string = '00000000-0000-0000-0000-000000000000';
  
  constructor(private store:Store, private loggingService:LoggingService, private router:Router, private httpClient:HttpClient, private authService: Auth0Service, private memberService: MemberService) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    if (!localStorage.getItem('memberId')) {
      this.loadMemberData();
    }
    this.userName = (localStorage.getItem('name') !== 'undefined') ? (localStorage.getItem('adminName') || 'Member') : 'Member';
    this.logoUrl = environment.clientMediaPath + '/icons/android-chrome-192x192.png';
    var currentVersion = localStorage.getItem("PtgPortalAppVersion");
    this.statusText = 'Checking for updates...';
    
    this.subs.add(
      combineLatest([
        this.store.pipe(select(AppSelectors.getUserName), filter(un => un != undefined)),
        this.store.pipe(select(AppSelectors.getImpersonation), filter(imp => imp != undefined))
      ]).subscribe(users => {
        var un = users[0];
        var imp = users[1];

        if(imp) {
          this.userName = imp.name;
        } else if(un) {
          this.userName = un;
        }
      })
    );

    this.subs.add(
      this.httpClient.get<any>(`${environment.apiUrl}/version`).subscribe({
        next: ver => {
          if(ver.value !== currentVersion) {
            this.statusText = 'Updates found. Reloading App....';
            this.loggingService.logTrace(`UPDATING App to Version: ${ver.value}.`);

            var $this = this;
            setTimeout(() => {
              localStorage.setItem("PtgPortalAppVersion", ver.value);
              $this.loadAppData();
            }, 750);
          } else {
            var $this = this;
            setTimeout(() => {
              $this.statusText = 'Checking for updates...everything up to date.';
              $this.loadAppData();
            }, 750);          
          }
        },
        error: err => {
          this.loggingService.logTrace('Unable to validate the version number.');
          this.loggingService.logException(err, 5);
          var $this = this;
          setTimeout(() => {
            $this.statusText = 'Checking for updates...everything up to date.';
            $this.loadAppData();
          }, 1000);          
        }
      })
    );  
  }

  loadMemberData(){
    var req = {
      oid: localStorage.getItem('sub') || '',
      name: localStorage.getItem('name') || '',
      email: localStorage.getItem('email') || '',
      domain: window.location.getClientDomain()
    } as AuthenticationRequest;
    var $this = this;
    this.memberService.authenticate(req).subscribe({
      next: async el => {
        localStorage.setItem('municipalId', el.municipalId || '');
        if (['SysAdmin', 'FundAdmin'].includes(el.group)) {
          $this.router.navigate(["prompt"]);
        }
        else if (el.group == 'Member') {
          localStorage.setItem('memberId', el.memberId || '');
          var userName = el.fullName;
          localStorage.setItem('memberName', userName || '');
          this.store.dispatch(AppDataActions.setUserName({ userName }));
          this.store.dispatch(ClientDataActions.loadClient({ id: window.location.getClientDomain() }))
          this.store.dispatch(MemberDataActions.loadMember({ id: el.memberId }));
          this.store.dispatch(PagesDataActions.loadPages());
        }
        this.store.dispatch(MemberDataActions.loadOptInMessage({clientId: el.municipalId, memberId: el.memberId}));
      },
      error: err => {
        $this.authService.logout();
      }
    })
    
  }

  loadAppData():void {
    this.statusText = 'Sit tight while your data is being refreshed.';

    this.subs.add(
      this.store.pipe(select(ClientSelectors.getClient), filter(client => client != undefined)).subscribe(client => {
        this.client = client;
        this.loadPages();
        this.progressBarCompleted(10);
      })
    );    

    
    this.subs.add(
      this.store.pipe(select(MemberSelectors.getMember), filter(member => member != undefined)).subscribe(member => {
        this.member = member as (MemberInfo & Member);
        this.loadMetadata();
        this.loggingService.logTrace(`Successfully fetched MEMBER Info: ${member.name} (${member.id}).`, {
          memberId: member.id,
          obj_val: member
        });
        this.progressBarCompleted(10);
      })
    );
  }

  loadPages() {
    this.store.dispatch(PagesDataActions.loadPages());
    this.subs.add(
      this.store.pipe(select(PagesSelectors.getPages), filter(pg => pg != undefined )).subscribe(pages => {
        if(pages.length > 0) {
          this.pageDefs = pages;
          this.firstPageId = pages[0].id;
        }

        this.progressBarCompleted(40);
      })
    );
  }

  loadMetadata() {
    var isNotUndefined = function(val:any, idx:number):boolean {
      return (val !== undefined);
    }    
    
    this.store.dispatch(MetadataActions.loadMetadata());
    this.subs.add(
      this.store.pipe(select(MetadataSelectors.getAll), filter(md => md != undefined)).subscribe(md => {
        let perpts:Number = Math.ceil(40 / md.length);
        console.log(`Metadata Percentage Points: ${perpts}`);
        console.log(md);
        for(let i = 0; i < md.length; i++) {
          this.store.dispatch(MemberDataActions.loadMetadata({ itemKey: md[i].entityPropertyId }))
          this.subs.add(
            this.store.select(MemberSelectors.getMetadata, { itemKey: md[i].entityPropertyId }).pipe(filter(isNotUndefined)).subscribe(mp => {
              var logMsg = `Successfully fetched ${md[i].entityPropertyId.toUpperCase()} for Member: ${this.member['firstName']} ${this.member['lastName']} (${this.member.id}).`;
              //console.log(logMsg);
              this.loggingService.logTrace(logMsg, {
                memberId: this.member.id,
                obj_val: mp
              });              
              this.progressBarCompleted(perpts);
            })            
          );
        }
        this.subs.add(
          this.store.select(MemberSelectors.getMemberError).pipe(filter(err => err?.itemKey != undefined)).subscribe(err => {
            var logMsg = `Cannot fetch ${err.itemKey.toUpperCase()} for Member: ${this.member.name} (${this.member.id}).`;
            console.log(logMsg);
            this.loggingService.logTrace(logMsg, {
              memberId: this.member.id,
              httpError: err.error
            });           
            this.progressBarCompleted(perpts);
          })
        );
      })
    );    
  }

  progressBarCompleted(percentageCompleted) {
    this.progressBarValue += percentageCompleted;
    
    if(this.progressBarValue >= 100) {
      this.statusText = 'Sucessfully refreshed your data. Loading App...';

      var $router = this.router;
      setTimeout(() => {
        $router.navigate(["pages", this.firstPageId], { 
          fragment: window.location.getClientDomain() 
        });
      }, 2000);
    }
  }

  private getDateParts(fieldDate?: Date): any {
    if(!fieldDate) {
      return { 
        year: null, 
        month: null, 
        day: null 
      };
    }

    return {
      year: fieldDate.getFullYear(),
      day: fieldDate.getDate(),
      month: (fieldDate.getMonth() + 1)
    };
  }
}