import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IconDefinition, faFileDownload, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest } from 'rxjs';
import { MemberSelectors } from 'src/app/member';
import { Member } from 'src/app/member/models';
import { MetadataModels, MetadataSelectors } from 'src/app/metadata';
import { PagesModels } from 'src/app/pages';
import { PagesService } from 'src/app/pages/services/pages.service';
import { LoggingService } from 'src/app/shared';
import { FileSnapshot } from 'src/app/shared/models';
import { SignatoryService } from 'src/app/shared/services/signatory-service';
import * as fileSaver from 'file-saver';
import { PropertyType } from 'src/app/client/types';
import { MemberInfo } from 'src/app/member/models/member';
import { Property } from 'src/app/metadata/models';

@Component({
  selector: 'psrs-member-detail-template',
  templateUrl: './member-detail.component.html',
  styleUrls: ['./member-detail.component.scss']
})
export class MemberDetailComponent implements OnInit, OnDestroy {
  private _page: PagesModels.PageDefinition;
  readonly ATTACHMENT_KEY = '#attachment';
  readonly PropertyType = PropertyType;
  propertyListAttachmentConfig: { sectionKey: string, label: string } = { sectionKey: '', label: '' };
  propertyTableAttachmentConfig: { sectionKey: string, label: string } = { sectionKey: '', label: '' };

  @Input() set page(value: PagesModels.PageDefinition) {
    this._page = value;
    this.redrawComponent();
  }

  get page(): PagesModels.PageDefinition {
    return this._page;
  }

  TEMPLATE_KEY:any = {
    SupportContact: "supportContact",
    Subheader: "subheader",
    PropertyList: "propertyList",
    PropertyListLayout: "propertyListLayout",
    PropertyTable: "propertyTable",
    Footnote: "footnote",
    Attachment: "attachment",
    AttachmentText: "attachmentText"
  };
  PROPERTY_TYPE:any = {
    SSN: "SSN",
    Phone: "Phone",
    Email: "Email",
    RichText: "RichText",
    Currency: "Currency",
    Date: "Date",
    DateTime: "DateTime",
    PersonName: "Person_Name",
    Address: "Address",
    Lookup: "Lookup",
    Percentage: "Percentage",
    Binary: "Binary",
    Boolean: "Boolean",
    Department: "Department",
    Employer: "Employer",
    Decimal: "Decimal",
  };

  AGGREGATION_TYPE: any = {
    Count: "Count",
    Min: "Min",
    Max: "Max",
    Sum: "Sum",
    Average: "Average",
  };

  subs:Subscription = new Subscription();
  faFilePdf:IconDefinition = faFilePdf;
  faFileDownload:IconDefinition = faFileDownload;
  fileAttachment:FileSnapshot;
  buttonText:string;
  memberMetadata:Member;
  member: MemberInfo;
  properties:MetadataModels.Property[];
  propertyList:any[] = [];
  propertyTable:any;
  listDisplay:string = 'Table';
  richTextContent:string;
  richTextTitle:string;
  @ViewChild("rickTextModal")
  rickTextModal: TemplateRef<any>;
  rickTextModalId: string;
  allSelected: boolean = false;
  sectionName: string = '';
  isSelected: boolean = false;
  isLoading: boolean = false;
  constructor(private sanitized:DomSanitizer, private store:Store, private loggingService:LoggingService, private router: Router
    , private signatoryService: SignatoryService, private currencyPipe:CurrencyPipe, private datePipe:DatePipe, private modalService:NgbModal,
    private pagesService: PagesService) { }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.add(
      combineLatest([
        this.store.select(MetadataSelectors.getAll),
        this.store.select(MemberSelectors.getMetadataAll),
        this.store.select(MemberSelectors.getMember),
      ]).subscribe(([md, mem, mb]) => {
        this.properties = md;
        this.memberMetadata = mem;
        this.member = mb;
        
        this.redrawComponent();
      })
    );
  }

  redrawComponent(): void {
    this.propertyList = [];

    let attachmentContent = this.page.content.find(c => c.key === this.TEMPLATE_KEY.Attachment);
    if(attachmentContent?.value != null) {
      var metadataContent = this.memberMetadata[attachmentContent.value];
      if(metadataContent && metadataContent.attachments != null && metadataContent.attachments.length > 0) {
        var attachments = [...metadataContent.attachments];
        attachments.sort(function(a,b){
          return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
        });

        this.fileAttachment = attachments[0].fileSnapshot;
        var attachmentText = this.page.content.find(c => c.key === this.TEMPLATE_KEY.AttachmentText);
        this.buttonText = attachmentText?.value ?? 'Download';
      }
    }
    
    if(this.properties) {
      let propertyListContent = this.page.content.find(c => c.key === this.TEMPLATE_KEY.PropertyList);
      let propertyListLayout = this.page.content.find(c => c.key === this.TEMPLATE_KEY.PropertyListLayout);

      // Set current property list layout
      if(propertyListLayout?.value) {
        this.listDisplay = propertyListLayout.value;
      }

      // Handle Draw property list
      if(propertyListContent?.value != null) {
        const attachmentConfig = (Object.values(propertyListContent.value) as string[])
          .find((value) => value.split('.')?.[0]?.includes(this.ATTACHMENT_KEY)) || '';
          
        this.propertyListAttachmentConfig = this.getAttachmentConfig(attachmentConfig);
        for (let idx in propertyListContent.value) {
          let parts = propertyListContent.value[idx].split('.');
          if (parts[0]?.includes(this.ATTACHMENT_KEY)) {
            continue;
          }

          let mdp = this.properties.find(x => (x.entityPropertyId === parts[1]));
          
          if(mdp) {
            const propertyList = this.member.memberInfo.propertyList;
            const retirementCalcKey = Object.keys(propertyList).find((key) => {
              const splitKey = key.split('.');
              if (parts?.[6]) {
                return splitKey?.[0] === mdp.entityPropertyId && parts[6] === splitKey?.[3];
              }
              return splitKey?.[0] === mdp.entityPropertyId;
            });
            
            let mdv = propertyList[retirementCalcKey] ?? null;

            if ([PropertyType.Email, PropertyType.Phone, PropertyType.SSN].includes(mdp?.type)) {
              if (typeof mdv === 'string') {
                mdv = { original: mdv };
              }
            }
            if (mdp?.type === PropertyType.Tier) {
              mdv = this.member.tiers.find(tier => tier.id === mdv)?.tierName;
            }
            
            this.propertyList.push({
              property: {
                ...mdp,
                type: +retirementCalcKey?.split(".")?.[2] ?? mdp.type,
                parentType: mdp.type,
                propertyLabel: parts[0].replace(/\%2E/g, "."),
              },
              value: mdv,
              isAggregated: false
            });
          }
        }
      }
      
      // Handle Draw property table
      let propertyTableContent = this.page.content.find(c => c.key === this.TEMPLATE_KEY.PropertyTable);

      if(propertyTableContent?.value != null) {
        const propertyTable = this.member.memberInfo.propertyTable;
        const contentValues = (Object.values(propertyTableContent.value) as string[]);
        const attachmentConfig = contentValues.find((value) => value.split('.')?.[0]?.includes(this.ATTACHMENT_KEY)) || '';

        this.propertyTableAttachmentConfig = this.getAttachmentConfig(attachmentConfig);
        
        if(contentValues?.length) {
          // Property table value
          const values = propertyTable?.map(data => {
            return {...Object.keys(data).reduce((acc, key) =>  {
              let value = data[key];
              const keySplit = key.split('.');

              if (+keySplit[2] === PropertyType.Tier) {
                value = this.member.tiers.find(tier => tier.id === value)?.tierName;
              }

              return ({...acc, [`${keySplit[0]}${keySplit?.[3] ? `.${keySplit[3]}` : ''}`]: [value, keySplit[2]]});
            }, {}), isSelected: false}
          });

          // Property table column
          const properties = contentValues.filter((item) => item.includes('.') && !item.includes(this.ATTACHMENT_KEY)).map(key => {
            const splitKey = key.split('.');
            const crrProperty = this.properties.find(property => property.entityPropertyId === splitKey[1]);

            return {
              ...crrProperty,
              ...((splitKey?.[8] && splitKey?.[8] != 'undefined') ? { entityPropertyId: `${crrProperty.entityPropertyId}.${splitKey?.[8]}` } : {}),
              propertyLabel: splitKey[0].replace(/\%2E/g, ".")
            };
          });

          this.propertyTable = {
            properties,
            values
          };
        } else {
          this.propertyTable = undefined;
        }        
      }
    }
  }

  toTimeZoneLocal(dateTime: Date) {
    return new Date(dateTime.setMinutes(dateTime.getMinutes() - new Date().getTimezoneOffset()));
  }

  getDisplayValue(prop: MetadataModels.MetadataProperty, propValue, valueType?: any): any {
    if (valueType && propValue.length > 0){
      propValue = propValue[0][prop.propertyKey];
    }    
    const type = prop.type;
    if((!prop.type || (!propValue && propValue != 0)) && type != PropertyType.Binary) {
      return null;
    }
    let propConfig = prop?.configs;

    if (prop?.configs?.calcucation && !prop?.configs?.fractionalLengthInput) {
      const config = JSON.parse(prop?.configs?.calcucation ?? {});
      propConfig = { fractionalLengthInput: config?.fractionalLength , ...config };
    }
    
    try {
      switch(type) {
        case PropertyType.Currency:
            propValue = this.currencyPipe.transform(propValue);
        break;
        case PropertyType.Date:
            propValue = this.datePipe.transform(propValue, 'MM/dd/yyyy');
            break;
        case PropertyType.DateTime:
            propValue = this.datePipe.transform(this.toTimeZoneLocal(new Date(propValue)), 'MM/dd/yyyy hh:mm a');
          break;
        case PropertyType.Person_Name:
          var persion = {
            prefix: propValue.prefix,
            first: propValue.first,
            middle: propValue.middle,
            last: propValue.last,
            suffix: propValue.suffix
          }
          propValue = new MetadataModels.PersonName(persion).full;
          break;
        case PropertyType.Address:
            var country = "";
            var state = "";
            
            if(propValue.country && prop?.options){
              country = this.getOptionValue(prop, propValue.country);
            }

            if(propValue.state && prop?.options){
              state = this.getOptionValue(prop, propValue.state);
            }

            var address = {
              street1: propValue.street1 ?? '',
              street2: propValue.street2 ?? '',
              city: propValue.city ?? '',
              state: (state || propValue.state) ?? '',
              zipCode: propValue.zipcode ?? '',
              zipPlus4: propValue.zipPlus4 ?? '',
              country: (country || propValue.country) ?? ''
            };
            propValue = new MetadataModels.Address(address).full;
          break;
        case PropertyType.Department:
        case PropertyType.Employer:
          propValue = this.getOptionValue(prop, propValue);
          break;
        case PropertyType.Percentage:
          propValue = `${propValue}%`;
          break;
        case PropertyType.Binary:
          if(propValue) {
            propValue = propConfig?.affirmative;
          } else {
            propValue = propConfig?.negative;
          }
          break;
        case PropertyType.SSN:
        case PropertyType.Email:
        case PropertyType.Phone:
          if(propConfig?.masked == 'true') {
            propValue = propValue.value != null ? propValue.value : propValue;
          } else{
            propValue = propConfig?.negative != null ? propConfig?.negative : propValue;
          }
          if (propValue?.maskedValue || propValue?.originalValue) {
            if(propConfig?.masked == 'true') {
              propValue = propValue?.maskedValue;
            } else{
              propValue = propValue?.originalValue;
            }
          }
          if (propValue?.maskedValue === "" && propValue?.originalValue === "") {
            propValue = "";
          }
          break;
        case PropertyType.Decimal:
            let fractionalLength = propConfig?.fractionalLengthInput && propConfig?.fractionalLengthInput != null ? propConfig?.fractionalLengthInput : 2;
            const decimalRegex = new RegExp('^-?\\d+(?:\.\\d{0,' + (+fractionalLength || 0) + '})?');
            propValue = Number(Number(propValue).toString().match(decimalRegex)?.[0] || '0').toLocaleString('en', { minimumFractionDigits: +fractionalLength });
          break;
        case PropertyType.Whole_Number:
            propValue = Number(Number(propValue)?.toString())?.toLocaleString('en');
          break;
      }
    } catch (error) {
      console.log(error);
    }

    return propValue;
  }

  isTypeNumber(value: unknown) {
    return typeof value === "number";
  }

  getTextAlignColumn(prop: Property) {
    let resultValue;
    for(let value of this.propertyTable.values){
      const propertyValue = this.getPropertyTableValue(value[prop.entityPropertyId], 0);
      if (propertyValue) {
        resultValue = propertyValue;
        break;
      }
    }

    return this.isTypeNumber(resultValue) ? 'right' : 'left';
  }

  getOptionValue(prop: MetadataModels.MetadataProperty, propValue, defaultValue = null): any {
    let opt = (prop.options?.length ? prop.options : prop.selectOptionTable).find(x => x.id === propValue || x.text === propValue || x?.code === propValue);
    return opt?.text ?? (defaultValue ?? '');
  }

  getAggregationValue(type:any, values: any, key: any, dataType: any): any {
    let count = 0;
    let total = 0;
    switch (type){
      case this.AGGREGATION_TYPE.Count:
        count = 0;
        values.forEach(element => {
          if(element[key]){
            count+=1;
          }
        });

        return count;

      case this.AGGREGATION_TYPE.Sum:
        total = 0;
        values.forEach(element => {
          total += element[key];
        });

        return total;

      case this.AGGREGATION_TYPE.Average:
        total = 0;
        count = 0;
        values.forEach(element => {
          total += element[key];
          count += 1;
        });

        return count > 0 ? total/count : count;

      case this.AGGREGATION_TYPE.Min:
        let min = '';
        values.forEach(element => {
          min = min == '' ? element[key] : (min > element[key] ? element[key]: min);
        });

        if(min != '' && dataType == this.PROPERTY_TYPE.DateTime){
          min = this.datePipe.transform(min, 'MM/dd/yyyy h:mm a');
        }

        return min;
      case this.AGGREGATION_TYPE.Max:
        let max = '';
        values.forEach(element => {
          max = max == '' ? element[key] : (max < element[key] ? element[key]: max);
        });

        if(max != '' && dataType == this.PROPERTY_TYPE.DateTime){
          max = this.datePipe.transform(max, 'MM/dd/yyyy h:mm a');
        }

        return max;
      default:
        return '';
    }
  }

  showRichTextModel(item: any, template: any): any {
    this.richTextContent = item.value;
    this.richTextTitle = item.property.propertyLabel;
    if(!this.modalService.hasOpenModals()) {
      this.modalService.open(template, {
        backdrop: 'static',
        scrollable: true, 
        keyboard: false,
        centered: true,
      });
    }
  }

  closeDisplay(): void {
    this.modalService.dismissAll();
  }

  onClickIcon(status: any) {
  }

  safeHtml(html: string): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(html);
  }

  viewAttachmentList(attachmentSectionKey?: string, rowId?: string) {
    if (!attachmentSectionKey) {
      return;
    }

    let attachmentList = this.memberMetadata?.[attachmentSectionKey]?.attachments || [];
    if (rowId) {
      attachmentList = attachmentList.filter(attachment => attachment.rowId === rowId);
    }
    if(attachmentList?.length === 1) {
      const downloadUrl = this.signatoryService.getUrl(attachmentList[0].fileSnapshot);
      if (downloadUrl) {
        this.loggingService.logEvent('User Downloaded Pdf');
        window.open(downloadUrl, '_blank');
        return;
      }
    }

    this.router.navigate(['pages', this.page.id, 'attachment-list'], {
      state: {
        page: this.page,
        attachmentList,
        rowId
      },
      fragment: window.location.getClientDomain()
    });
  }

  getAttachmentConfig(attachmentConfig: string) {
    if (!attachmentConfig) {
      return { sectionKey: '', label: '' };
    }
    return {
      sectionKey: attachmentConfig?.split('.')[1] || '',
      label: attachmentConfig?.split('.')[2] || '',
    };
  }

  hasAttachment(attachmentSectionKey: string): boolean {
    return !!attachmentSectionKey;
  }

  onChange(event: any, data?: any) {
    const valueInput = event.target.checked;
    if(data) {
      data.isSelected = valueInput;
      this.allSelected = this.propertyTable.values.every(value => value.isSelected);
    } else {
      this.allSelected = valueInput;
      this.propertyTable.values.forEach(value => {
        if(value.noOfAttachments) {
          value.isSelected = this.allSelected;
        }
      });
    }
    const findValueSelected = this.propertyTable.values.find(value => value.isSelected);
    this.isSelected = findValueSelected ? true : false;
  }

  getPropertyTableValue(value: any, indexValue: number) {
    if (!value) {
      return null;
    }

    return value?.[indexValue];
  }

  downloadMulti(attachmentSectionKey: string) {
    const listFileSelected = this.propertyTable.values.filter(value => value.isSelected);
    if (!attachmentSectionKey || !listFileSelected?.length) {
      return false;
    }
    if(listFileSelected.length === 1 && listFileSelected[0].noOfAttachments === 1) {
      this.viewAttachmentList(attachmentSectionKey, listFileSelected[0].rowId);
      return false;
    }
    if(!this.sectionName) {
      this.sectionName = this.properties.find((value: any) => value.key === attachmentSectionKey)?.name;
    }
    let listAttachmentSelected = [];
    listFileSelected.forEach(value => {
      const checkAttachment = this.memberMetadata[attachmentSectionKey]?.attachments.filter(item => item.rowId === value.rowId);
      if(checkAttachment.length) {
        listAttachmentSelected = listAttachmentSelected.concat(checkAttachment);
      }
    });
    const body = listAttachmentSelected.map(item => item.fileSnapshot.key);
    this.isLoading = true;
    this.pagesService.exportMultiFile(body).subscribe(res => {
      if (res) {
        const blob = this.base64ToBlob(res, 'text/plain');
        const date = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        fileSaver.saveAs(blob, `${this.sectionName}_${date}.zip`);
        // reset data
        this.propertyTable.values.forEach(item => {
          item.isSelected = false;
        });
        this.allSelected = false;
        this.isSelected = false;
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  base64ToBlob(b64Data, contentType='', sliceSize=512) {
    b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }
}
